// import { addRecord } from '$lib/forms/call';
import { returnDayjs } from '$lib/schemas/utils';
import type { ChangeEvent } from 'sveltekit-superforms';

interface T extends Record<string, unknown> {}
interface InputType { [x: string]: unknown; }


function setField(name:string, value:unknown, input:InputType, event?:ChangeEvent<T>) {
	if (event) event.set(name, value);
	else input[name] = value;
	return input;
}
function getField(name:string, input:InputType, event?:ChangeEvent<T>) {
	if (event) return event.get(name)
	else {
		return returnNestedData(name, input);
	}
}
function returnNestedData(name:string, input:InputType) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return name.split('.').reduce((acc: any, part) => acc && acc[part], input);
}

export const appointmentChanged = (input:InputType, event?:ChangeEvent<T>) => {
	if (!event || event?.path === 'clientData.isAppointment') {
		const start = getField('start', input, event);
		const minDate = getField('clientData.minDate', input, event);
		const startDayjs = returnDayjs(getField('start', input, event));
		const minDateDayjs = returnDayjs(minDate);
		const isAppointment = getField('clientData.isAppointment', input, event);

		if (isAppointment) {
			if (!start || startDayjs.isBefore(minDateDayjs)) {
				setField('start', minDate, input, event);
			}
		}else {
			setField('start', null, input, event);
			setField('priority', 'Normal', input, event);
		}
	}
	return input;
}
export const startChanged = function (input:InputType, event?:ChangeEvent<T>) {
	if (!event || event?.path === 'start') {
		const startValue = getField('start', input, event);
		if (startValue) {
			setField('start', trimStartDate(startValue), input, event);
		}
	}
	return input
}

export const trimStartDate = function (input:string) {
	return input.replace(/(?<timeAndHours>.*T\d\d:)(?<mins>\d\d)(:(?<secs>\d\d)|)/g, (_, timeAndHours) => `${timeAndHours}00`)
}

export function logPathChange(input:InputType, name: string, event:ChangeEvent<T>) {
	if (event.paths.includes(name)) {
		console.group();
		console.log('event.paths', event.paths);
		console.log(`formdata ${name}`, input[name]);
		console.log(`event ${name}`, event.get(name));
		console.groupEnd();
	}
}