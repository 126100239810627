import * as v from 'valibot'
import { BookingSourceSchema, BookingNameSchema, BookingAddressSchema, BookingCustomerTypeSchema, BookingSummarySchema, BookingIsFirstTimeClientSchema, BookingIsSendConfirmationEmailSchema, BookingPrioritySchema, BookingContactArraySchema, BookingExternalIdSchema, BookingCampaignIdSchema, BookingBusinessUnitIdSchema, BookingJobTypeIdSchema, BookingUploadedImagesSchema, BookingPhoneContactSchema, BookingEmailContactSchema } from '$lib/schemas/booking';
import { trimStartDate, appointmentChanged, startChanged } from '$lib/schemas/booking/actions';
import { messageTemplates } from '$lib/schemas/utils';

export const ScheduleSchema = v.pipe(
	v.object({
		name: BookingNameSchema,
		customerType: BookingCustomerTypeSchema,
		contacts: v.nullish(BookingContactArraySchema),
		phone: BookingPhoneContactSchema,
		email: BookingEmailContactSchema,
		address: BookingAddressSchema,
		summary: BookingSummarySchema,
		start: v.nullish(
			v.pipe(
				v.string(),
				v.isoDateTime(),
				v.transform((input) => trimStartDate(input))
			)
		),
		priority: BookingPrioritySchema,
		source: BookingSourceSchema,
		isFirstTimeClient: BookingIsFirstTimeClientSchema,
		isSendConfirmationEmail: BookingIsSendConfirmationEmailSchema,
		externalId: v.nullish(BookingExternalIdSchema,null),
		campaignId: v.nullish(BookingCampaignIdSchema,null),
		businessUnitId: v.nullish(BookingBusinessUnitIdSchema,null),
		jobTypeId: v.nullish(BookingJobTypeIdSchema,null),
		uploadedImages: v.nullish(BookingUploadedImagesSchema,null),
		clientData: v.object({
			isAppointment: v.optional(
				v.boolean(),
				false
			),
			minDate: v.pipe(
				v.string(),
				v.minLength(1, 'The hidden "clientData.minDate" field is not set correctly. Please contact your developer.'),
				v.isoDateTime('The hidden "clientData.minDate" field is not set correctly. Please contact your developer.')
			)
		})
	}),
	v.forward(
		v.partialCheck(
			[['clientData','isAppointment'], ['start']],
			(input) => (input.clientData.isAppointment && !input.start) ? false : true,
			messageTemplates.required('Appointment Date')
		),
		['start']
	),
	v.forward(
		v.partialCheck(
			[['start'], ['clientData', 'minDate']],
			(input) => {
				if (!input.start || !input.clientData.minDate) return true;
				const start = new Date(input.start);
				const startMin = new Date(input.clientData.minDate);
				return start >= startMin
			},
			'Date must be at least 2 hours from now.'
		),
		['start']
	),
	v.transform((input) => {
		input = appointmentChanged(input) as typeof input;
		input = startChanged(input) as typeof input;
		return input
	})
)

export type ScheduleSchemaType = typeof ScheduleSchema;
export type ScheduleSchemaData = v.InferOutput<ScheduleSchemaType>;
export type ScheduleSchemaKey = keyof ScheduleSchemaData