<script lang="ts" context="module">
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	import type { FormPath } from 'sveltekit-superforms';
	// the form object
	type T = Record<string, unknown>;
	// the path/name of the field in the form object
	type U = unknown;
</script>

<script lang="ts" generics="T extends Record<string, unknown>, U extends FormPath<T>">
	import { page } from '$app/stores';
	import { setContext } from 'svelte';
	import { superForm, type SuperValidated, type Infer, type InferIn } from 'sveltekit-superforms';
	import { valibotClient } from 'sveltekit-superforms/adapters';
	import { phoneTypeOptions, customerTypeOptions } from '$lib/forms/schedule/lists';
	import { ScheduleSchema, type ScheduleSchemaType } from '$lib/forms/schedule/schema';
	import { Form, Fieldset, Textarea, Radio, Checkbox, Input, Select, FieldCol, Submit } from '$lib/components/forms/v2';
	import { usStates, phoneMask, AppointmentRules } from '$lib/schemas/utils';
	import { appointmentChanged, startChanged } from '$lib/schemas/booking/actions';

	let ScheduleFormSuperValidated: SuperValidated<Infer<ScheduleSchemaType>, App.Superforms.Message, InferIn<ScheduleSchemaType>> = $page.data.scheduleForm;

	const form = superForm(ScheduleFormSuperValidated, {
		validators: valibotClient(ScheduleSchema),
		dataType: 'json',
		stickyNavbar: '#page-header',
		onChange(event) {
			appointmentChanged($formData, event);
			startChanged($formData, event);
		},
		onResult({ formElement }) {
			if (formElement) formElement.scrollIntoView();
		}
	});

	const { form: formData, reset } = form;

	const apptRules = new AppointmentRules();
	setContext('apptRules', apptRules);

	const defaultState = {
		clientData: {
			isAppointment: false,
			minDate: apptRules.min.formatted.dateTimeLocal
		}
	};
	reset({
		data: defaultState,
		newState: defaultState
	});
</script>

<Form {form}>
	<Fieldset legend="How can we help you?">
		<Textarea name="summary" label="Describe your problem" rows="5" bind:value={$formData.summary} {form} />
		<div class="pb-4">
			<Radio
				name="clientData.isAppointment"
				label="Would you like to schedule an appointment now?"
				options={[
					{ label: 'Yes', value: true },
					{ label: 'No', value: false }
				]}
				bind:group={$formData.clientData.isAppointment}
				{form}
			/>
		</div>
		{#if $formData.clientData.isAppointment}
			<div class="flex flex-col gap-4">
				<Input type="datetime-local" name="start" bind:value={$formData.start} label="Starting Time Window" description="Windows are 3 hours on whole hours only" min={defaultState.clientData.minDate} step={3600} {form} />
			</div>
			<div class="pb-4">
				<Checkbox name="priority" label="Is this an emergency?" toggleValues={['Urgent', 'Normal']} {form} />
			</div>
		{/if}
	</Fieldset>
	<Fieldset legend="Contact Information">
		<svelte:fragment slot="description">
			<p>One of our technicians will call to confirm the details of your request</p>
		</svelte:fragment>
		<FieldCol breakpoint="md">
			<Input type="text" name="name" label="Name" autocomplete="name" bind:value={$formData.name} {form} />
			<Select name="customerType" label="Customer Type" bind:value={$formData.customerType} {form}>
				{#each customerTypeOptions as customerType (customerType.id)}
					<option value={customerType.id}>{customerType.name}</option>
				{/each}
			</Select>
		</FieldCol>
		<FieldCol breakpoint="md">
			<Input type="tel" name="phone.value" label="Phone" autocomplete="tel-national" use={phoneMask} bind:value={$formData.phone.value} {form} />
			<Select name="phone.type" label="Phone Type" bind:value={$formData.phone.type} {form}>
				{#each phoneTypeOptions as phoneType (phoneType.id)}
					<option value={phoneType.id}>{phoneType.name}</option>
				{/each}
			</Select>
		</FieldCol>
		<!-- <Input type="text" name="company" label="Company (Optional)" autocomplete="organization" {form} /> -->
		<FieldCol breakpoint="md">
			<Input type="text" name="address.street" label="Address" autocomplete="address-line1" bind:value={$formData.address.street} {form} />
			<Input type="text" name="address.unit" label="Apartment/Suite" autocomplete="address-line2" bind:value={$formData.address.unit} {form} />
		</FieldCol>
		<FieldCol breakpoint="md">
			<Input type="text" name="address.city" label="City" autocomplete="address-level2" bind:value={$formData.address.city} {form} />
			<FieldCol breakpoint="sm">
				<Select name="address.state" label="State" autocomplete="address-level1" bind:value={$formData.address.state} {form}>
					<option value=""></option>
					{#each usStates as state}
						<option value={state.abbr}>{state.abbr}</option>
					{/each}
				</Select>
				<Input type="text" name="address.zip" label="Zip" size="15" autocomplete="postal-code" bind:value={$formData.address.zip} {form} />
			</FieldCol>
		</FieldCol>
		<Input type="email" name="email.value" label="Email" autocomplete="email" bind:value={$formData.email.value} {form} />
	</Fieldset>
	<!-- <Fieldset legend="Special Instructions (Optional)">
		<svelte:fragment slot="description">
			<p>e.g. call 30min ahead, use the back door, etc.</p>
		</svelte:fragment>
		<Textarea name="instructions" label="Instructions..." rows="5" {form} />
	</Fieldset> -->
	<Submit {form} />
</Form>
