<script lang="ts" context="module">
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	import type { FormPath } from 'sveltekit-superforms';
	// the form object
	type T = Record<string, unknown>;
	// the path/name of the field in the form object
	type U = unknown;
</script>

<script lang="ts" generics="T extends Record<string, unknown>, U extends FormPath<T>">
	import type { SuperForm } from 'sveltekit-superforms';
	import { stringProxy } from 'sveltekit-superforms';
	import { Field, FieldGroup } from '$lib/components/forms/v2';
	import { Label } from 'formsnap';
	import type { Action } from 'svelte/action';

	export let form: SuperForm<T>;
	export let name: U;
	export let checked: FormPath<T> | unknown = undefined;
	export let hide = false;
	export let label: string;
	export let options: { label?: string; value: unknown }[] = [];
	export let toggleValues: unknown[] | undefined = undefined;
	export let use: Action = () => void 0;

	const { form: formData } = form;

	let isChecked = false;
	const proxy = stringProxy(formData, name, { empty: 'undefined' });
	$: {
		if (toggleValues) {
			if (isChecked) {
				$proxy = toggleValues[0] as string;
			} else {
				$proxy = toggleValues[1] as string;
			}
		}
	}
</script>

{#if options.length > 1}
	<FieldGroup type="checkbox" legend={label} {name} {options} {form} {hide} />
{:else if toggleValues}
	<Field type="checkbox" {name} {form} {hide} let:fieldClasses let:attrs>
		<Label class={fieldClasses.label}>
			<input use:use type="checkbox" {...attrs} class={fieldClasses.field} bind:checked={isChecked} />
			<span>{label}</span>
		</Label>
	</Field>
{:else}
	<Field type="checkbox" {name} {form} {hide} let:fieldClasses let:attrs>
		<Label class={fieldClasses.label}>
			<input use:use type="checkbox" {...attrs} class={fieldClasses.field} bind:checked />
			<span>{label}</span>
		</Label>
	</Field>
{/if}
