<script lang="ts">
	export let breakpoint: "sm" | "md" | "lg" | "xl" | "none" = "md";
	export let gap = "gap-6";

	const classMap = {
		col: "flex-col",
		rowBreakpoint: {
			sm: "sm:flex-row",
			md: "md:flex-row",
			lg: "lg:flex-row",
			xl: "xl:flex-row",
			none: ""
		}
	};

	const classes = [classMap.col, classMap.rowBreakpoint[breakpoint], gap].join(" ");
</script>

<div class="flex {classes}">
	<slot />
</div>
