import * as v from 'valibot'
import { CleanStringSchema } from '$lib/schemas/generic';
import { messageTemplates, valuePatterns, usStates } from '$lib/schemas/utils';

export const BookingAddressSchema = v.object({
	street: v.pipe(
		CleanStringSchema,
		v.nonEmpty(messageTemplates.required('Address'))
	),
	unit: v.optional(CleanStringSchema),
	city: v.pipe(
		CleanStringSchema,
		v.nonEmpty(messageTemplates.required('City'))
	),
	state: v.optional(
		v.picklist(
			usStates.map((usState) => usState.abbr),
			messageTemplates.required('State')
		)
	),
	zip: v.pipe(
		CleanStringSchema,
		v.nonEmpty(messageTemplates.required('Zip Code')),
		v.regex(
			valuePatterns.postalCode.test,
			'Must be valid U.S. ZIP Code'
		)
	),
	country: v.optional(
		v.literal('USA'),
		'USA'
	)
})
export type BookingAddressSchemaType = v.InferOutput<typeof BookingAddressSchema>;
export type BookingAddressSchemaKeys = keyof BookingAddressSchemaType;