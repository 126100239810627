<script lang="ts" context="module">
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	import { type FormPath } from 'sveltekit-superforms';

	// the form object
	type T = Record<string, unknown>;
	// the path/name of the field in the form object
	type U = unknown;
</script>

<script lang="ts" generics="T extends Record<string, unknown>, U extends FormPath<T>">
	import { Label } from 'formsnap';
	import type { SuperForm } from 'sveltekit-superforms';
	import { Field } from '$lib/components/forms/v2';
	import type { Action } from 'svelte/action';

	export let form: SuperForm<T>;
	export let name: U;
	export let value: FormPath<T> | unknown;
	export let hide = false;
	export let label: string | null = null;
	export let use: Action = () => void 0;
	export let description: string | undefined = undefined;
</script>

<Field type="select" {form} {name} {hide} {description} let:fieldClasses let:attrs>
	{#if label}<Label class={fieldClasses.label}>{label}</Label>{/if}
	<select use:use bind:value class={fieldClasses.field} {...attrs} {...$$restProps}>
		<slot />
	</select>
</Field>
