import * as v from 'valibot'
import { CleanStringSchema } from '$lib/schemas/generic';
import { messageTemplates, valuePatterns, maskitoTransform } from '$lib/schemas/utils';
import { phoneTypeOptions } from '$lib/forms/schedule/lists';

// Email Types
export type EmailContactType = 'Email';
type EmailContactTypeOption = { id: EmailContactType; name: string; }

// Phone Types
export type PhoneContactType = 'Phone' | 'MobilePhone' | 'Fax';

// Contact Types
export type ContactType = PhoneContactType | EmailContactType;
type ContactTypeOption = { id: ContactType; name: string; }

export const bookingEmailContactType:ContactType = 'Email'
export const bookingPhoneContactTypes = ['Phone','MobilePhone','Fax'] as const;
export const bookingContactTypes = ['Phone','MobilePhone','Fax','Email'] as const;


// Memo Schemas
export const BookingContactMemoSchema = v.nullable(CleanStringSchema)

// Phone Schema
export const BookingPhoneContactSchema = v.object({
	type: v.optional(
		v.picklist(
			bookingPhoneContactTypes,
			messageTemplates.list('Phone Type', phoneTypeOptions.map((item) => item.name))
		),
		'MobilePhone'
	),
	value: v.pipe(
		CleanStringSchema,
		v.nonEmpty(messageTemplates.required('Phone Number')),
		v.transform((val) => {
			val = val + '';
			const parsed = maskitoTransform(val,valuePatterns.phone.mask)
			return parsed
		}),
		v.regex(
			valuePatterns.phone.test,
			messageTemplates.pattern('Phone', valuePatterns.phone.pattern)
		)
	),
	memo: BookingContactMemoSchema
})
export type BookingPhoneSchemaType = v.InferOutput<typeof BookingPhoneContactSchema>;

// Email Schema
export const BookingEmailContactSchema = v.object({
	type: v.optional(
		v.literal(bookingEmailContactType),
		bookingEmailContactType
	),
	value: v.pipe(
		CleanStringSchema,
		v.email('Not a valid email')
	),
	memo: BookingContactMemoSchema
})
export type BookingEmailSchemaType = v.InferOutput<typeof BookingEmailContactSchema>;

export const BookingContactSchema = v.variant('type',[
	v.object({
		type: v.picklist(
			['Phone','MobilePhone','Fax'],
			messageTemplates.list('Phone Type', phoneTypeOptions.map((item) => item.name))
		),
		value: v.pipe(
			CleanStringSchema,
			v.nonEmpty(messageTemplates.required('Phone Number')),
			v.transform((val) => {
				val = val + '';
				const parsed = maskitoTransform(val,valuePatterns.phone.mask)
				return parsed
			}),
			v.regex(
				valuePatterns.phone.test,
				messageTemplates.pattern('Phone', valuePatterns.phone.pattern)
			)
		),
		memo: BookingContactMemoSchema
	}),
	v.object({
		type: v.literal(bookingEmailContactType),
		value: v.pipe(
			CleanStringSchema,
			v.email('Not a valid email')
		),
		memo: BookingContactMemoSchema
	})
])

export type BookingContactSchemaType = v.InferOutput<typeof BookingContactSchema>;

export const BookingContactArraySchema = v.array(BookingContactSchema)

