<script lang="ts">
	export let fieldsetClasses = 'flex flex-col gap-6';
	export let legendClasses = 'font-headline text-2xl';
	export let descriptionClasses = 'text-md text-tee-gray-default';

	export let legend: string;
</script>

<fieldset class={fieldsetClasses}>
	<div>
		<legend class={legendClasses}>{legend}</legend>
		<div class={descriptionClasses}>
			<slot name="description" />
		</div>
	</div>
	<slot />
</fieldset>
