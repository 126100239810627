<script lang="ts" context="module">
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	import { type FormPath } from 'sveltekit-superforms';

	// the form object
	type T = Record<string, unknown>;
	// the path/name of the field in the form object
	type U = unknown;
</script>

<script lang="ts" generics="T extends Record<string, unknown>, U extends FormPath<T>">
	import { Label } from 'formsnap';
	import type { SuperForm } from 'sveltekit-superforms';
	import { Field } from '$lib/components/forms/v2';

	export let form: SuperForm<T>;
	export let name: U;
	export let value: FormPath<T> | unknown;
	export let hide = false;
	export let label: string | null = null;
</script>

<Field type="textarea" {form} {name} {hide} let:fieldClasses let:attrs>
	{#if label}<Label class={fieldClasses.label}>{label}</Label>{/if}
	<textarea bind:value class={fieldClasses.field} {...attrs} {...$$restProps}></textarea>
</Field>
