<script lang="ts" context="module">
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	import { type FormPath } from 'sveltekit-superforms';

	// the form object
	type T = Record<string, unknown>;
	// the path/name of the field in the form object
	type U = unknown;
</script>

<script lang="ts" generics="T extends Record<string, unknown>, U extends FormPath<T>">
	import type { SuperForm } from 'sveltekit-superforms';
	import { FieldGroup } from '$lib/components/forms/v2';

	export let name: U;
	export let form: SuperForm<T>;
	export let hide = false;
	export let label: string | undefined;
	export let options: { label: string; value: unknown }[] = [];
	export let group: U | boolean;
</script>

<FieldGroup type="radio" legend={label} {name} {options} {form} {hide} bind:group />
