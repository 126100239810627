<script lang="ts" context="module">
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	import { type FormPath } from 'sveltekit-superforms';
	import type { Action } from 'svelte/action';

	// the form object
	type T = Record<string, unknown>;
	// the path/name of the field in the form object
	type U = unknown;
</script>

<script lang="ts" generics="T extends Record<string, unknown>, U extends FormPath<T>">
	import type { SuperForm } from 'sveltekit-superforms';
	import { getFieldClasses, ValidationMessage, Field } from '$lib/components/forms/v2';
	import { Fieldset, Control, Legend, Label } from 'formsnap';
	import { resolvePath } from '$lib/schemas/utils';

	export let name: U;
	export let form: SuperForm<T>;
	export let type: 'radio' | 'checkbox';
	export let hide = false;
	export let legend: string | undefined;
	export let options: { label: string; value: unknown }[] = [];
	export let group: U | boolean;
	export let use: Action = () => void 0;

	export let fieldsetClasses = 'space-y-2';
	export let layoutClasses = 'flex flex-col flex-auto gap-1';

	$: {
		if (hide) fieldsetClasses += ' hidden';
	}

	const { form: formData, errors: formErrors } = form;

	$: fieldValue = resolvePath(name, $formData);
	$: fieldErrors = resolvePath(name, $formErrors);
	$: hasValue = (fieldValue?.toString().length || 0) > 0;
	$: isValid = !fieldErrors;

	let isFocused: boolean = false;
	function handleFocus(e: Event) {
		isFocused = e.type == 'focusin';
	}

	$: fieldClasses = getFieldClasses({ fieldType: type, isValid, isFocused, hasValue, isHidden: hide, name });
</script>

{#if type !== 'checkbox' || options.length > 1}
	<Fieldset class={fieldsetClasses} {form} {name}>
		{#if legend}
			<!-- eslint-disable-next-line svelte/no-at-html-tags -->
			<Legend class={fieldClasses.legend}>{@html legend}</Legend>
		{/if}
		<div class={layoutClasses}>
			<div class={fieldClasses.fieldWrapper} on:focusin={handleFocus} on:focusout={handleFocus}>
				<slot name="inputs">
					{#each options as { value, label }, idx}
						<Control let:attrs>
							<Label class={`${fieldClasses.label} ${idx > 0 ? 'pl-4' : ''}`}>
								{#if type == 'radio'}
									<input use:use {...attrs} type="radio" class={fieldClasses.field} bind:group {value} />
								{:else if type == 'checkbox'}
									<input use:use {...attrs} type="checkbox" class={fieldClasses.field} bind:group {value} />
								{/if}
								<!-- eslint-disable-next-line svelte/no-at-html-tags -->
								<span>{@html label}</span>
							</Label>
						</Control>
					{/each}
				</slot>
			</div>
			<ValidationMessage />
		</div>
	</Fieldset>
{:else}
	<Field {form} {name} let:value let:errors let:tainted let:constraints>
		<div class={layoutClasses}>
			<div class={fieldClasses.fieldWrapper} on:focusin={handleFocus} on:focusout={handleFocus}>
				<Control let:attrs>
					<div class="relative">
						<slot {attrs} {fieldClasses} {value} {errors} {tainted} {constraints} />
					</div>
				</Control>
			</div>
			<ValidationMessage />
		</div>
	</Field>
{/if}
