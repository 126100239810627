import * as v from 'valibot'
import { messageTemplates } from '$lib/schemas/utils';

export const bookingSources = [
	{ id: "ELocal", name: "ELocal" },
	{ id: "Google Local Services", name: "Google Local Services" },
	{ id: "Yelp", name: "Yelp" },
	{ id: "Google Ads", name: "Google Ads" },
	{ id: "Dex", name: "Dex" },
	{ id: "Google Organic", name: "Google Organic" },
	{ id: "Hibu", name: "Hibu" },
	{ id: "Seven 800 Numbers", name: "Seven 800 Numbers" },
	{ id: "Jimmy Kelley", name: "Jimmy Kelley" },
	{ id: "Talk Local", name: "Talk Local" },
	{ id: "Website", name: "Website" },
	{ id: "Other", name: "Other" }
]

export const BookingSourceSchema = v.nullish(
	v.picklist(
		bookingSources.map((leadSource) => leadSource.id),
		messageTemplates.required('Lead Source')
	),
	"Other"
)