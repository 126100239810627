import type { MaskitoOptions } from '@maskito/core';
import { Maskito, type MaskitoElement } from '@maskito/core';
import type { Action } from 'svelte/action';

const phoneMaskito = {
  mask: [ '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, ' ', 'x', /\d/, /\d/, /\d/, /\d/]
} satisfies MaskitoOptions;

const postalCodeMaskito = {
  mask: [ /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
} satisfies MaskitoOptions;

export const valuePatterns = {
	phone: {
		pattern: '(000) 000-0000',
		test: /^\(\d{3}\)\s\d{3}-\d{4}($|\sx[0-9]+$)/,
		mask: phoneMaskito
	},
	postalCode: {
		pattern: '00000{-0000}',
		test: /^\d{5}(-\d{4})?$/,
		mask: postalCodeMaskito
	},
	dateTimeLocal: {
		pattern: '0000-00-00T00:00',
		test: /(?<date>(?<year>\d{4})-(?<month>0[1-9]|1[0-2])-(?<day>0[1-9]|[12][0-9]|3[01]))T(?<time>(?<hour>0[1-9]|1[0-9]|2[0-4]):(?<minutes>0[0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9]))/
	}
}
type ArraytoList = (array:string[], params:{delimiter?: string, lastDelimiter?: string}) => string;

const arraytoList:ArraytoList = function(array, {delimiter = ', ', lastDelimiter = ' and '}) {
	return array.reduce( (a, b, i, array) => a + (i < array.length - 1 ? delimiter : lastDelimiter) + b)
}

export const messageTemplates = {
	required: (name: string) => `${name} is required`,
	pattern: (name: string, pattern: string) => `${name} must be formatted like: ${pattern}`,
	valid: (name: string, type: string) => `${name} isn't a valid ${type}`,
	list: (name: string, options:string[]) => `${name} must be one of: ${arraytoList(options,{lastDelimiter: ' or '})}`
}
export const postalCodeMask: Action = (node) => {
	const maskedElement = new Maskito(node as unknown as MaskitoElement, valuePatterns.postalCode.mask);

	return {
		destroy() {
			maskedElement.destroy();
		}
	};
};
export const phoneMask: Action = (node) => {
	const maskedElement = new Maskito(node as unknown as MaskitoElement, valuePatterns.phone.mask);
	return {
		destroy() {
			maskedElement.destroy();
		}
	};
};