import * as v from 'valibot'
import {
	BookingSourceSchema,
	BookingNameSchema,
	BookingAddressSchema,
	BookingContactSchema,
	BookingCustomerTypeSchema,
	BookingStartSchema,
	BookingSummarySchema,
	BookingCampaignIdSchema,
	BookingBusinessUnitIdSchema,
	BookingJobTypeIdSchema,
	BookingPrioritySchema,
	BookingIsFirstTimeClientSchema,
	BookingUploadedImagesSchema,
	BookingIsSendConfirmationEmailSchema,
	BookingExternalIdSchema
} from '$lib/schemas/booking';


export const BookingSchema = v.object({
	name: BookingNameSchema,
	customerType: v.nullish(BookingCustomerTypeSchema),
	contacts: v.nullish(v.array(BookingContactSchema)),
	address: v.nullish(BookingAddressSchema),
	summary: BookingSummarySchema,
	start: BookingStartSchema,
	priority: v.nullish(BookingPrioritySchema),
	source: BookingSourceSchema,
	isFirstTimeClient: BookingIsFirstTimeClientSchema,
	isSendConfirmationEmail: v.nullish(BookingIsSendConfirmationEmailSchema),
	externalId: BookingExternalIdSchema,
	campaignId: v.nullish(BookingCampaignIdSchema),
	businessUnitId: v.nullish(BookingBusinessUnitIdSchema),
	jobTypeId: v.nullish(BookingJobTypeIdSchema),
	uploadedImages: v.nullish(BookingUploadedImagesSchema),
})

export type BookingSchema = typeof BookingSchema;
export type BookingSchemaInput = v.InferInput<BookingSchema>;
export type BookingSchemaData = v.InferOutput<BookingSchema>;